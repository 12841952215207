.fs-my-account-header {
    margin-top: 40px;
}

.fs-my-account-header-content {
    background-color: var(--white);
    border: var(--border) 1px solid;
    border-radius: var(--br-12);
    overflow: hidden;
}

.fs-my-account-header-content .ant-row {
    align-items: stretch;
}

.fs-my-account-header-img {
    position: relative;
}

.fs-my-account-header-img .ant-image {
    width: 100%;
    border-right: var(--border) 1px solid;
}

.fs-my-account-header-img .fs-circle-btn {
    position: absolute;
    top: 20px;
    right: 20px;
}

.fs-my-account-header-img .ant-image-img {
    height: 600px;
    object-fit: cover;
    width: 100%;
}

.fs-my-account-header-meta {
    padding: 40px 25px 40px 55px;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.fs-my-account-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    padding-left: 55px;
    padding-right: 25px;
}

.fs-my-account-top .ant-select {
    width: 250px;
}

.fs-my-account-header-middle .ant-tag {
    font-size: 18px;
    text-transform: uppercase;
    height: 37px;
    line-height: 37px;
    padding-left: 15px;
    padding-right: 15px;
}

.fs-my-account-header-middle h1.ant-typography {
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.fs-my-account-contact-list {
    margin-top: 20px;
}

.fs-my-account-contact-list li {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.fs-my-account-contact-list li svg {
    width: 12px;
    height: 12px;
    fill: var(--primary);
    margin-right: 8px;
}

.fs-my-account-social {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.fs-my-account-social li {
    margin-right: 10px;
}

.fs-my-account-social li a {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 37px;
    text-align: center;
    color: var(--white);
    border-radius: 50px;
}

.fs-my-account-social li a:hover {
    color: var(--white);
}

.fs-my-account-header-middle .ant-btn {
    margin-top: 25px;
}

.fs-user-rating {
    margin-top: 15px;
}

.upload-profile-image .ant-upload-list-picture-card-container {
    width: 100%;
    height: 400px;
    margin: 0;
    margin-bottom: 20px;
}

.upload-profile-image .ant-upload-list-item-actions .ant-btn {
    display: none;
    background-color: none !important;
}

.upload-profile-image .ant-upload-list-item-actions a {
    display: none;
    background-color: none !important;
}

.upload-profile-image .ant-btn {
    background-color: var(--primary);
    color: var(--white);
    border: none;
    width: 100%;
}

.upload-profile-image .ant-btn:hover,
.upload-profile-image .ant-btn:focus {
    background: #ff4942;
    color: var(--white);
    border: none;
}

.fs-my-contact {
    display: flex;
}

.fs-my-contact h2 {
    margin-left: 10px;
    margin-right: 10px;
}

.fs-my-contact svg {
    margin-top: 5px;
}

.modelScroll {
    overflow-y: scroll;
    height: 450px;
}

.upload-profile-image .ant-upload-list-item-actions .ant-btn {
    padding: 0;
}

.upload-profile-image .ant-upload.ant-upload-select-picture-card {
    height: auto;
    width: 100%;
    border: none;
    margin: 0;
}

.upload-profile-image .ant-btn-icon-only.ant-btn-sm {
    width: 30px;
    height: 30px;
    padding-top: 0 !important;
}

.fs-mandatory {
    margin-top: 15px;
    color: #ff4942;
    font-size: 16px;
    font-weight: 500;
    animation: blink 2s linear infinite;
}

.fs-upload-spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000000;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@media(min-width: 1440px) {
    .fs-my-account-header-middle h1.ant-typography {
        font-size: 36px;
    }
}

@media(max-width: 991px) {

    .fs-my-account-header-meta {
        padding: 30px;
    }

    .fs-my-account-top {
        padding-left: 30px;
        top: 30px;
    }

    .fs-my-account-header-middle h1.ant-typography {
        font-size: 26px;
    }

    .fs-my-account-header-middle .ant-tag {
        font-size: 16px;
        height: 32px;
        line-height: 32px;
    }
}

@media(max-width: 767px) {
    .fs-my-account-header {
        margin-top: 30px;
    }

    .fs-my-account-header-middle {
        margin-top: 80px;
    }

    .fs-my-account-header-img .ant-image-img {
        height: 400px;
    }

    .fs-my-account-header-img .ant-image {
        border-right: none;
    }
}

@media(max-width: 576px) {
    .fs-my-account-header {
        margin-top: 20px;
    }

    .fs-my-account-header-img .ant-image-img {
        height: 300px;
    }

    .fs-my-account-header-meta {
        padding: 20px;
    }

    .fs-my-account-top {
        padding-left: 20px;
        padding-right: 20px;
        top: 20px;
    }

    .fs-my-account-header-middle {
        margin-top: 70px;
    }
}