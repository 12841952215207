.fs-blog-detail-img .ant-image {
    width: 50%;
}

.fs-blog-detail-img .ant-image-img {
    object-fit: cover;
    height: calc(90vh - 111px);
    border-radius: 0;
    margin-top: 0;
}

.fs-blog-detail .fs-container {
    max-width: 900px;
    padding-top: 40px;
    padding-bottom: 60px;
}

.fs-blog-detail h1.ant-typography {
    font-size: 30px;
}

.fs-blog-detail p {
    margin-top: 30px;
    line-height: 1.5;
}

.fs-blog-detail h2 {
    margin-top: 30px;
}

.fs-blog-detail img {
    border-radius: 14px;
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin-top: 20px;
}

@media(min-width: 1440px) {
    .fs-blog-detail h1.ant-typography {
        font-size: 36px;
    }
}

@media(max-width: 1191px) {
    .fs-blog-detail .fs-container {
        padding-top: 30px;
    }

    .fs-blog-detail h1.ant-typography {
        font-size: 26px;
    }
}

@media(max-width: 991px) {
    .fs-blog-detail-img .ant-image-img {
        height: 350px;
    }

    .fs-blog-detail .fs-container {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .fs-blog-detail h1.ant-typography {
        font-size: 22px;
    }

    .fs-blog-detail .fs-blog-list {
        margin-top: 15px;
    }

    .fs-blog-detail img {
        height: 400px;
        margin-top: 10px;
    }
}

@media(max-width: 787px) {
    .fs-blog-detail-img .ant-image-img {
        height: 300px;
    }

    .fs-blog-detail .fs-container {
        padding-bottom: 0;
    }

    .fs-blog-detail p {
        margin-top: 20px;
    }

    .fs-blog-detail img {
        height: 300px;
    }
}

@media(max-width: 576px) {
    .fs-blog-detail-img .ant-image-img {
        height: 250px;
    }

    .fs-blog-detail h1.ant-typography {
        font-size: 18px;
    }

    .fs-blog-detail img {
        height: 250px;
    }
}

.ant-tag-blog {
    width: max-content;
    margin-top: 10px;
    background-color: red;
    color: white;
    border-radius: 6px;
    border: none;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
}

.blog-image-author {
    height: 100% !important;
    margin-top: 0px !important;
    margin-right: 15px;
}